import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/society-details',
    // name: 'Property Details',
    // redirect: '/users/index',
    children: [
      {
        path: '/society-details/:id',
        name: 'Society Details',
        component: () => import('@/views/property_details/PropertyDetailsView.vue')
      }
    ]
  },
  {
    path: '/society-details/:id/amenities',
    children: [
      {
        path: '/society-details/:id/amenities',
        name: 'Society Amenities',
        component: () => import('@/views/property_sections/PropertyAmenitiesView.vue'),
        props: true
      }
    ]
  },
  {
    path: '/society-details/:id/galleries',
    children: [
      {
        path: '/society-details/:id/galleries',
        name: 'Society Galleries',
        component: () => import('@/views/property_sections/PropertyGalleriesView.vue'),
        props: true
      }
    ]
  },
  {
    path: '/society-details/:id/location',
    children: [
      {
        path: '/society-details/:id/location',
        name: 'Society Location',
        component: () => import('@/views/property_sections/SocietyLocationView.vue'),
        props: true
      }
    ]
  },
  {
    path: '/society-details/:id/map',
    children: [
      {
        path: '/society-details/:id/map',
        name: 'Society Map',
        component: () => import('@/views/property_sections/SocietyMapView.vue'),
        props: true
      }
    ]
  },
  {
    path: '/society-details/:id/development-update',
    children: [
      {
        path: '/society-details/:id/development-update',
        name: 'Society Development Update',
        component: () => import('@/views/property_sections/SocietyDevelopmentView.vue'),
        props: true
      }
    ]
  },
  {
    path: '/society-details/:id/payment-plan',
    children: [
      {
        path: '/society-details/:id/payment-plan',
        name: 'Society Payment Plan',
        component: () => import('@/views/property_sections/SocietyPaymentPlanView.vue'),
        props: true
      }
    ]
  },
  {
    path: '/thanks',
    children: [
      {
        path: '/thanks',
        name: 'Thanks',
        component: () => import('@/views/ThanksView.vue'),
        props: true
      }
    ]
  },
  {
    path: '/calculator',
    name: 'calculator',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CalculatorView.vue')
  }
]

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    // always scroll to top
    return { top: 0 }
  }
})

export default router
