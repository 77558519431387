<template>
  <!-- Contact -->
  <section id="contact" class="tm-section-pad-top tm-parallax-2">

    <div class="container tm-container-contact">

      <div class="row">
        <!-- ---------------------------------- -->
        <div class="col-sm-12 col-md-4">
          <div class="contact-item pdl">
            <a href="/" class="item-link navbar-brand">
              <img src="/img/logo edit 10.png" width="75" height="75" class="d-inline-block align-top" alt="">
            </a>
          </div>

          <div class="contact-item pdl">
            <a rel="nofollow" href="#" class="item-link">
              <span class="mb-0">Head Office: Faizpur Interchange, Main Sharaqpur Road Lahore</span>
            </a>
          </div>

          <div class="contact-item pdl">
            <a rel="nofollow" href="#" class="item-link">
              <!-- <i class="fas fa-2x fa-phone-square mr-4"></i> -->
              <span class="mb-0">+92-42-111-333-987</span>
            </a>
          </div>

          <div class="contact-item pdl">
            <a rel="nofollow" href="#" class="item-link">
              <span class="mb-0">Care@aljalildevelopers.com</span>
            </a>
          </div>
        </div>
        <!-- ---------------------------- -->
        <div class="col-sm-12 col-md-2">
          <div class="contact-item">
            <span class="mb-0 foot-section-title">QUICK LINKS</span>
          </div>

          <div class="contact-item mrgt">
            <a rel="nofollow" href="#" class="item-link">
              <span class="mb-0">Blog and Media</span>
            </a>
          </div>

          <div class="contact-item">
            <a rel="nofollow" href="#" class="item-link">
              <span class="mb-0">Feedback</span>
            </a>
          </div>

          <div class="contact-item">
            <a rel="nofollow" href="#" class="item-link">
              <span class="mb-0">Authorized Dealers</span>
            </a>
          </div>

          <div class="contact-item">
            <a rel="nofollow" href="#" class="item-link">
              <span class="mb-0">Newsletter</span>
            </a>
          </div>
        </div>
        <!-- ---------------------------------------------- -->
        <div class="col-sm-12 col-md-2">
          <div class="contact-item">
            <span class="mb-0 foot-section-title">SITE LINKS</span>
          </div>

          <div class="contact-item mrgt">
            <a rel="nofollow" href="#" class="item-link">
              <span class="mb-0">About Us</span>
            </a>
          </div>

          <div class="contact-item">
            <a rel="nofollow" href="#" class="item-link">
              <span class="mb-0">Contact Us</span>
            </a>
          </div>

          <div class="contact-item">
            <a rel="nofollow" href="#" class="item-link">
              <span class="mb-0">Virtual Tour</span>
            </a>
          </div>

          <div class="contact-item">
            <a rel="nofollow" href="#" class="item-link">
              <span class="mb-0">Our Policies</span>
            </a>
          </div>
        </div>
        <!-- -------------------------------------------- -->
        <div class="col-sm-12 col-md-4">
          <div class="contact-item">
            <span class="mb-0 foot-section-title">GET IN TOUCH</span>
          </div>
          <form action="" method="get" class="mrgt">
            <span class="mb-0">JOIN OUR NEWSLETTER</span>
            <input id="email" name="email" type="email" placeholder="Your Email" class="tm-input" required/>
            <button type="submit" class="btn tm-btn-submit">Submit</button>
          </form>
        </div>
      </div><!-- row ending -->
    </div>
    <footer class="text-center small tm-footer">
      <p class="mb-0">
        © Copyright {{ getCurrentYear() }} Sastayplots.com. All Rights Reserved</p>
    </footer>
  </section>

</template>
<script>
export default {
  name: 'FooterComponent',
  props: {
    msg: String
  },
  methods: {
    getCurrentYear () {
      return new Date().getFullYear()
    }
  }
}
</script>
