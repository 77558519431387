<template>
  <section id="whatwedo" class="tm-section-pad-top">
    <div class="container">
      <div class="row tm-content-box"><!-- first row -->
        <div class="col-lg-12 col-xl-12">
          <div class="tm-intro-text-container text-center">
            <h2 class="tm-text-primary mb-4 tm-section-title">
              ABOUT US
              <span class="underbar ubar1"></span>
            </h2>
            <p class="mb-4 tm-intro-text text-justify">
              Al-Jalil Developers is a leading company in the real estate, housing, and infrastructure sectors
              in Pakistan. Our portfolio includes a range of prestigious projects, such as Al Jalil Garden
              Housing Scheme, the LDA-approved largest housing project in Lahore West; the Al-Noor Orchard
              Housing Scheme, and two premium housing societies in Sheikhupura; Al-Baari Residencia and Al
              Aziz Residencia offering luxurious residential development. Additionally, we are the creators of
              Pakistan's first-ever purpose-built entertainment city, the Lahore Entertainment City.
            </p>
            <p class="mb-4 tm-intro-text text-justify">
              With a strong track record of developing mega commercial projects and introducing community
              living to Pakistan, Al-Jalil Developers has earned a reputation for excellence and innovation.
              We are committed to providing our customers with not just houses, but with peace of mind and a
              secure future. Our values of vigilance, quality, and customer satisfaction have allowed us to
              earn the trust and loyalty of generations of satisfied customers.
            </p>
          </div>
        </div>
      </div><!-- first row -->
    </div>

  </section>

  <section id="gallery" class="tm-section-pad-top">
    <div class="container tm-container-gallery">
      <div class="row">
        <div class="text-center col-12">
          <h2 class="tm-text-primary tm-section-title mb-4">
            OUR PROJECTS
            <span class="underbar ubar2"></span>
          </h2>
          <p class="mx-auto tm-section-desc">
            <!-- Praesent sed pharetra lorem, blandit convallis mi. Aenean ornare elit ac metus lacinia, sed iaculis nibh semper. Pellentesque est urna, lobortis eu arcu a, aliquet tristique urna. -->
          </p>
        </div>
      </div>
      <!-- ---------------------------------------------------------------------- -->
      <div v-if="societies.length > 0">
        <div class="container pb-5">
          <div class="site-sectio bg-left-hal mb-5">
            <div class="container owl-2-style">
              <div class="owl-carousel owl-2">
                <div class="media-29101" v-for="(society, index) in societies" :key="index">
                  <router-link :to="getPropertyDetailsLink(society.slug)">
                    <img :src="getImageUrl(society.logo)" :alt="society.name" class="img-fluid">
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center" v-else>
        <!-- Loading or placeholder content -->
        <p>Loading...</p>
      </div>
      <!-- ---------------------------------------------------------------------- -->
    </div>
  </section>
  <!-- ----------------------Project section------------------------ -->

  <section id="testimonials" class="tm-section-pad-top tm-parallax-2">
    <div class="container tm-testimonials-content">
      <div class="row container">
        <!-- --------------------------------------------- -->
        <div class="text-center col-12">
          <h2 class="tm-section-title mb-4">
            OUR LOCATION
            <span class="underbar ubar3"></span>
          </h2>
          <p class="mb-5">
            Write Us Your Query
          </p><br>
        </div>
        <div class="row">
          <div class="col-lg-6 col-sm-12 w100">
            <!--Google map-->
            <div id="map-container-google-1" class="z-depth-1-half map-container">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d13614.571909601576!2d74.2530764812559!3d31.451494410653964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1687378419747!5m2!1sen!2s"
                      allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

            </div>
            <!--Google Maps-->
          </div>
          <!-- -------------------------------------------- -->
          <div class="col-lg-6 col-sm-12 w100 mt-05">
            <form @submit="submitForm">
              <input id="name" v-model="name" type="text" placeholder="Name" class="tm-input-query" required/>
              <input id="number" v-model="phone_number" type="text" placeholder="Phone Number" class="tm-input-query" required/>
              <input id="email" v-model="email" type="email" placeholder="Enter Email Address" class="tm-input-query" required/>
              <select id="society_id" v-model="society_id" class="tm-input-query" required>
                <option value="" disabled selected>--Select Society--</option>
                <option v-for="(society, index) in allSocieties" :key="index" :value="society.id">{{ society.name }} - {{ society.city.name}}</option>
              </select>
              <textarea id="message" v-model="message" rows="6" placeholder="Message" class="tm-input-query" required></textarea>
              <button type="submit" class="btn tm-btn-submit-query">Submit</button>
            </form>
          </div>
        </div><!-- row ending -->
      </div>

    </div>
  </section>
</template>
<script>
import { API_BASE_URL, IMAGE_URL } from '@/config/config'
import axios from 'axios'
import router from '@/router'
export default {
  name: 'HomeComponent',
  props: {
    msg: String
  },
  data () {
    return {
      pageTitle: 'Sastay Plots',
      societies: [],
      allSocieties: [],
      imageUrl: `${IMAGE_URL}societies/images/`,
      name: '',
      phone_number: '',
      email: '',
      society_id: '',
      message: ''
    }
  },
  async mounted () {
    await this.fetchSocieties().then(() => {
      this.$nextTick(() => {
        this.fetchAllSocieties()
        this.initBackgroundParallax()
        this.initBackgroundParallax2()
        this.handleWindowResize()
        this.closeMobileMenuAfterClick()
        this.initSinglePageNav()
        this.addSmoothScrolling()
        this.initMagnificPopup()
        this.initTestimonialsCarousel()
        this.initGallerySlider()
        this.initOwlCarousel()
      })
    })
  },
  methods: {
    getImageUrl (image) {
      // You may need to adjust this based on your actual data structure
      return `${this.imageUrl}${image}`
    },
    getPropertyDetailsLink (propertyId) {
      // You may need to adjust this based on your routing or linking logic
      return `society-details/${propertyId}`
    },
    async fetchSocieties () {
      const endpoint = `${API_BASE_URL}/societies-web`
      await axios
        .get(endpoint)
        .then((response) => {
          this.societies = response.data.data
        })
        .catch((error) => {
          console.error(error)
        })
    },
    async fetchAllSocieties () {
      const endpoint = `${API_BASE_URL}/societies-web-all`
      await axios
        .get(endpoint)
        .then((response) => {
          this.allSocieties = response.data.data
        })
        .catch((error) => {
          console.error(error)
        })
    },
    getOffset () {
      let offset = 450
      const windowHeight = window.innerHeight

      if (windowHeight > 500) {
        offset = 400
      }
      if (windowHeight > 680) {
        offset = 300
      }
      if (windowHeight > 830) {
        offset = 210
      }

      return offset
    },
    setParallaxPosition (multiplier, $object) {
      const offset = this.getOffset()
      const fromTop = document.documentElement.scrollTop
      const bgCss = `center ${multiplier * fromTop - offset}px`
      $object.style.backgroundPosition = bgCss
    },
    initBackgroundParallax () {
      const $object = document.querySelector('.tm-parallax')
      if ($object) {
        const multiplier = 0.3
        window.addEventListener('scroll', () => {
          this.setParallaxPosition(multiplier, $object)
        })
      }
    },
    initBackgroundParallax2 () {
      const $object = document.querySelector('#contact')
      if ($object) {
        const multiplier = 0.8
        $object.style.backgroundAttachment = 'fixed'

        window.addEventListener('scroll', () => {
          if (window.innerWidth > 768) {
            const firstTop = $object.offsetTop
            const pos = document.documentElement.scrollTop
            const yPos = Math.round(multiplier * (firstTop - pos) - 186)
            const bgCss = `center ${yPos}px`
            $object.style.backgroundPosition = bgCss
          } else {
            $object.style.backgroundPosition = 'center'
          }
        })
      }
    },
    handleWindowResize () {
      window.addEventListener('resize', () => {
        this.initBackgroundParallax()
      })
    },
    closeMobileMenuAfterClick () {
      const tmNavLinks = document.querySelectorAll('#tmNav a')
      tmNavLinks.forEach(link => {
        link.addEventListener('click', () => {
          const navbarCollapse = document.querySelector('.navbar-collapse')
          navbarCollapse.classList.remove('show')
        })
      })
    },
    initSinglePageNav () {
      // eslint-disable-next-line no-undef
      $('#tmNav').singlePageNav({
        easing: 'easeInOutExpo',
        speed: 600
      })
    },
    addSmoothScrolling () {
      // eslint-disable-next-line no-undef
      $('a').on('click', function (event) {
        if (this.hash !== '') {
          event.preventDefault()
          const hash = this.hash
          // eslint-disable-next-line no-undef
          $('html, body').animate(
            {
              // eslint-disable-next-line no-undef
              scrollTop: $(hash).offset().top
            },
            600,
            'easeInOutExpo',
            function () {
              window.location.hash = hash
            }
          )
        }
      })
    },
    initMagnificPopup () {
      // eslint-disable-next-line no-undef
      $('.tm-galley').magnificPopup({
        delegate: 'a',
        type: 'image',
        gallery: { enabled: true }
      })
    },
    initTestimonialsCarousel () {
      // eslint-disable-next-line no-undef
      $('.tm-testimonials-carousel').slick({
        dots: true,
        prevArrow: false,
        nextArrow: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      })
    },
    initGallerySlider () {
      // eslint-disable-next-line no-undef
      $('.tm-gallery').slick({
        dots: true,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 2,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      })
    },
    async submitForm (event) {
      event.preventDefault()
      const endpoint = `${API_BASE_URL}/contact-us`
      const saveContactUsData = new FormData()
      saveContactUsData.append('name', this.name)
      saveContactUsData.append('phone_number', this.phone_number)
      saveContactUsData.append('email', this.email)
      saveContactUsData.append('society_id', this.society_id)
      saveContactUsData.append('message', this.message)
      await axios
        .post(endpoint, saveContactUsData, {
          // headers: {
          //   Authorization: 'Bearer ' + this.token,
          // },
        })
        .then((response) => {
          if (response.data.success === true) {
            this.name = this.phone_number = this.email = this.society_id = this.message = ''
            router.push({ name: 'Thanks' })
          } else {
            this.isSubmitting = false
          }
        })
        .catch((error) => {
          if (error.response.status === 422 && error.response.data.errors) {
            // Handle validation errors
            this.errors = Object.values(error.response.data.errors).flat()
          } else {
            // Handle other errors
            this.errors = [error.response.data.message]
          }
        })
    },
    initOwlCarousel () {
      // if (this.carouselItems.length > 0) {
      this.$nextTick(() => {
        // eslint-disable-next-line no-undef
        $('.owl-2').owlCarousel({
          center: false,
          items: 1,
          loop: true,
          stagePadding: 0,
          margin: 20,
          smartSpeed: 1000,
          autoplay: true,
          nav: true,
          dots: true,
          pauseOnHover: false,
          responsive: {
            600: {
              margin: 20,
              nav: true,
              items: 2
            },
            1000: {
              margin: 20,
              stagePadding: 0,
              nav: true,
              items: 3
            }
          }
        })
      })
      this.showCarousel = true
      // }
    }
  }
}
</script>

<style scoped>
  /*! elementor - v3.14.0 - 26-06-2023 */
  .elementor-widget-image-carousel .swiper,
  .elementor-widget-image-carousel .swiper-container {
    position: static
  }

  .elementor-widget-image-carousel .swiper-container .swiper-slide figure,
  .elementor-widget-image-carousel .swiper .swiper-slide figure {
    line-height: inherit
  }

  .elementor-widget-image-carousel .swiper-slide {
    text-align: center
  }

  .elementor-image-carousel-wrapper:not(.swiper-container-initialized):not(.swiper-initialized) .swiper-slide {
    max-width: calc(100% / var(--e-image-carousel-slides-to-show, 3))
  }
</style>
