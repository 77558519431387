// config.js
// export const API_BASE_URL = 'http://property-new.example.com/api'
// export const IMAGE_URL = 'http://property-new.example.com/'

// export const API_BASE_URL = 'http://new-property.example.com/api'
// export const IMAGE_URL = 'http://new-property.example.com/'
// export const APP_URL = 'http://localhost:8080/'

export const APP_URL = 'http://localhost:8080/'
// export const APP_URL = 'https://sastayplots.com/'

// export const API_BASE_URL = 'https://api.sastayplots.com/api'
// export const IMAGE_URL = 'https://api.sastayplots.com/'

export const API_BASE_URL = 'https://api.getbestinternetspeed.com/api'
export const IMAGE_URL = 'https://api.getbestinternetspeed.com/'

export const MAP_API_KEY =
  'pk.eyJ1Ijoibm91bWFuYWxpMDA2IiwiYSI6ImNreTJ6NDFyaDA5a2IzMXVpdm1iMHhnaGcifQ.nTC8CXaEkAEuUKAHNGCC5Q'

//  FIND ID FROM GOOGLE
// export const MAP_API_KEY =
//   'pk.eyJ1IjoibW5hdWZhbGF6a2lhIiwiYSI6ImNrcWV4MzN0ZDA2NjQyd2xrazhhbG96eHMifQ.3R_B2O8m_CQ_tERmmji8mA'
