<template>
  <HeaderComponent></HeaderComponent>
  <HomeComponent></HomeComponent>
  <FooterComponent></FooterComponent>
</template>

<script>
import HeaderComponent from '@/components/Header.vue'
import HomeComponent from '@/components/Home.vue'
import FooterComponent from '@/components/Footer.vue'

export default {
  name: 'HomeView',
  components: { FooterComponent, HomeComponent, HeaderComponent },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
