<template>
  <!-- Hero section -->
  <section id="infinite" class="text-white tm-font-big tm-parallax">
    <div class="video-container">
      <video autoplay loop muted plays-inline class="backin">
        <source src="/videos/30sec video.mp4" type="video/mp4">
      </video>
    </div>

    <!-- Navigation -->
    <nav class="navbar navbar-expand-md tm-navbar" id="tmNav">
      <div class="container">
        <div class="tm-next">
          <router-link to="/" class="navbar-brand"><img src="/img/logo edit 9.png" width="75" height="75"
                                                        class="d-inline-block align-top" alt="We change the logo"></router-link>
        </div>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <i class="fas fa-bars navbar-toggler-icon"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <router-link class="nav-link tm-nav-link" :to="{ name: 'about' }">ABOUT US</router-link>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link tm-nav-link">PROJECTS</a>
              <ul>
                <li class="nav-item" v-for="(society, index) in societies" :key="index">
                  <router-link class="nav-link tm-nav-link"
                               :to="'/society-details/' + society.slug"
                  >{{ society.name }}</router-link>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link tm-nav-link" >PAYMENT PLAN</a>
              <ul>
                <li class="nav-item" v-for="(society, index) in societies" :key="index">
                  <router-link class="nav-link tm-nav-link"
                               :to="'/society-details/'+ society.slug+'/payment-plan'"
                  >{{ society.name }}</router-link>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link tm-nav-link">GALLERY</a>
              <ul>
                <li class="nav-item" v-for="(society, index) in societies" :key="index">
                  <router-link class="nav-link tm-nav-link"
                               :to="'/society-details/'+ society.slug+'/galleries'"
                  >{{ society.name }}</router-link>
                </li>
              </ul>
            </li>
<!--            <li class="nav-item">-->
<!--              <a class="nav-link tm-nav-link" href="#">VIRTUAL TOUR</a>-->
<!--            </li>-->
            <li class="nav-item">
              <router-link class="nav-link tm-nav-link" :to="{ name: 'calculator' }">Calculator</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </section>
</template>

<script>
import { API_BASE_URL } from '@/config/config'
import axios from 'axios'

export default {
  name: 'HeaderComponent',
  props: {
    msg: String
  },
  data () {
    return {
      societies: []
    }
  },
  mounted () {
    const $object = document.querySelector('.tm-navbar')
    // Scroll event
    window.addEventListener('scroll', () => {
      if (document.documentElement.scrollTop > 120) {
        $object.classList.add('scroll')
      } else {
        $object.classList.remove('scroll')
      }
    })
    this.fetchSocieties()
  },
  methods: {
    async fetchSocieties () {
      const endpoint = `${API_BASE_URL}/societies-web`
      await axios
        .get(endpoint)
        .then((response) => {
          this.societies = response.data.data
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

/*li {*/
/*  display: inline-block;*/
/*  margin: 0 10px;*/
/*}*/

a {
  color: #42b983;
}

.tm-navbar .dropdown ul {
  display: block;
  position: absolute;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0 0 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.tm-navbar .dropdown ul li {
  min-width: 220px;
}

.tm-navbar .dropdown ul a {
  color: var(--green);
}

.tm-navbar .dropdown ul a:hover,
.tm-navbar .dropdown ul .active:hover,
.tm-navbar .dropdown ul li:hover>a {
  color: #fff;
}

.tm-navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
</style>
