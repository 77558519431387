<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  mounted () {
    // this.initBackgroundParallax()
    // this.initBackgroundParallax2()
    // this.handleWindowResize()
    // this.handleWindowScroll()
    // this.closeMobileMenuAfterClick()
    // this.initSinglePageNav()
    // this.addSmoothScrolling()
    // this.initMagnificPopup()
    // this.initTestimonialsCarousel()
    // this.initGallerySlider()
  },
  methods: {
    // getOffset () {
    //   let offset = 450
    //   const windowHeight = window.innerHeight
    //
    //   if (windowHeight > 500) {
    //     offset = 400
    //   }
    //   if (windowHeight > 680) {
    //     offset = 300
    //   }
    //   if (windowHeight > 830) {
    //     offset = 210
    //   }
    //
    //   return offset
    // },
    // setParallaxPosition (multiplier, $object) {
    //   const offset = this.getOffset()
    //   const fromTop = document.documentElement.scrollTop
    //   const bgCss = `center ${multiplier * fromTop - offset}px`
    //   $object.style.backgroundPosition = bgCss
    // },
    // initBackgroundParallax () {
    //   const $object = document.querySelector('.tm-parallax')
    //   if ($object) {
    //     const multiplier = 0.3
    //     window.addEventListener('scroll', () => {
    //       this.setParallaxPosition(multiplier, $object)
    //     })
    //   }
    // },
    // initBackgroundParallax2 () {
    //   const $object = document.querySelector('#contact')
    //   if ($object) {
    //     const multiplier = 0.8
    //     $object.style.backgroundAttachment = 'fixed'
    //
    //     window.addEventListener('scroll', () => {
    //       if (window.innerWidth > 768) {
    //         const firstTop = $object.offsetTop
    //         const pos = document.documentElement.scrollTop
    //         const yPos = Math.round(multiplier * (firstTop - pos) - 186)
    //         const bgCss = `center ${yPos}px`
    //         $object.style.backgroundPosition = bgCss
    //       } else {
    //         $object.style.backgroundPosition = 'center'
    //       }
    //     })
    //   }
    // },
    // handleWindowResize () {
    //   window.addEventListener('resize', () => {
    //     this.initBackgroundParallax()
    //   })
    // },
    // handleWindowScroll () {
    //   window.addEventListener('scroll', () => {
    //     const navbar = document.querySelector('.tm-navbar')
    //     if (document.documentElement.scrollTop > 120) {
    //       navbar.classList.add('scroll')
    //     } else {
    //       navbar.classList.remove('scroll')
    //     }
    //   })
    // },
    // closeMobileMenuAfterClick () {
    //   const tmNavLinks = document.querySelectorAll('#tmNav a')
    //   tmNavLinks.forEach(link => {
    //     link.addEventListener('click', () => {
    //       const navbarCollapse = document.querySelector('.navbar-collapse')
    //       navbarCollapse.classList.remove('show')
    //     })
    //   })
    // },
    // initSinglePageNav () {
    //   // eslint-disable-next-line no-undef
    //   $('#tmNav').singlePageNav({
    //     easing: 'easeInOutExpo',
    //     speed: 600
    //   })
    // },
    // addSmoothScrolling () {
    //   // eslint-disable-next-line no-undef
    //   $('a').on('click', function (event) {
    //     if (this.hash !== '') {
    //       event.preventDefault()
    //       const hash = this.hash
    //       // eslint-disable-next-line no-undef
    //       $('html, body').animate(
    //         {
    //           // eslint-disable-next-line no-undef
    //           scrollTop: $(hash).offset().top
    //         },
    //         600,
    //         'easeInOutExpo',
    //         function () {
    //           window.location.hash = hash
    //         }
    //       )
    //     }
    //   })
    // },
    // initMagnificPopup () {
    //   // eslint-disable-next-line no-undef
    //   $('.tm-galley').magnificPopup({
    //     delegate: 'a',
    //     type: 'image',
    //     gallery: { enabled: true }
    //   })
    // },
    // initTestimonialsCarousel () {
    //   // eslint-disable-next-line no-undef
    //   $('.tm-testimonials-carousel').slick({
    //     dots: true,
    //     prevArrow: false,
    //     nextArrow: false,
    //     infinite: false,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     responsive: [
    //       {
    //         breakpoint: 992,
    //         settings: {
    //           slidesToShow: 2
    //         }
    //       },
    //       {
    //         breakpoint: 768,
    //         settings: {
    //           slidesToShow: 2
    //         }
    //       },
    //       {
    //         breakpoint: 480,
    //         settings: {
    //           slidesToShow: 1
    //         }
    //       }
    //     ]
    //   })
    // },
    // initGallerySlider () {
    //   // eslint-disable-next-line no-undef
    //   $('.tm-gallery').slick({
    //     dots: true,
    //     infinite: false,
    //     slidesToShow: 5,
    //     slidesToScroll: 2,
    //     responsive: [
    //       {
    //         breakpoint: 1199,
    //         settings: {
    //           slidesToShow: 4,
    //           slidesToScroll: 2
    //         }
    //       },
    //       {
    //         breakpoint: 991,
    //         settings: {
    //           slidesToShow: 3,
    //           slidesToScroll: 2
    //         }
    //       },
    //       {
    //         breakpoint: 767,
    //         settings: {
    //           slidesToShow: 2,
    //           slidesToScroll: 1
    //         }
    //       },
    //       {
    //         breakpoint: 480,
    //         settings: {
    //           slidesToShow: 1,
    //           slidesToScroll: 1
    //         }
    //       }
    //     ]
    //   })
    // }
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
